<template>
  <div class="login-admin">
    <div class="login-box">
      <div class="title">客服登录</div>
      <Form :model="data">
        <FormItem>
          <Input
            v-model="data.account_name"
            placeholder="请输入登录账号"
            prefix="ios-person"
            size="large"
          ></Input>
        </FormItem>
        <FormItem>
          <Input
            v-model="data.password"
            placeholder="请输入密码"
            prefix="md-unlock"
            type="password"
            size="large"
          ></Input>
        </FormItem>
        <Button type="primary" size="large" long @click="handleLogin"
          >登录</Button
        >
      </Form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      data: {
        account_name: "",
        password: "",
      },
    };

  },
  mounted() {
    const token = this.$getToken();
    if (token) {
      this.UserInfo().then((res) => {
        this.$Message[res.status ? "success" : "error"](res.msg);
        if (res.status) {
          this.$router.push("/chat");
        }
      });;
    }
  },
  methods: {
    ...mapActions(["Login", "UserInfo"]),
    handleLogin() {
      if (!this.data.account_name) {
        this.$Message.error("请输入账户名");
        return false;
      }
      if (this.data.account_name.length < 6) {
        this.$Message.error("账户名错误");
        return false;
      }
      if (!this.data.account_name) {
        this.$Message.error("请输入登录密码");
        return false;
      }
      if (this.data.password.length < 6) {
        this.$Message.error("登录密码错误");
        return false;
      }
      this.Login(this.data).then((res) => {
        this.$Message[res.status ? "success" : "error"](res.msg);
        if (res.status) {
          this.$router.push("/chat");
        }
      });
    },
  },
  computed: mapGetters({}),
  watch: {},
};
</script>

<style lang='less' scoped>
@--white: #fff;
@--black: #000;
@--bg: #f8f8f8;
@--grey: #999;
@--dark: #1a1a1a;
@--light: #e6e6e6;
@--wrapper: 1000px;
@--blue: #00b0ff;
.login-admin {
  background-color: @--bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  background-image: url("../../assets/image.jpeg");
  background-size: cover;
  background-repeat: none;
  height: 100%;
  width: 100%;
  .login-box {
    width: 400px;
    height: 250px;
    background: @--white;
    position: relative;
    top: 50%;
    left: 50%;
    background-color: @--white;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 10px;
    .title {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid @--bg;
    }
  }
}
</style>